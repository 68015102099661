:root {
  --main-size: 1600px;
  --main-bg-color: #edf2f5;
  --main-color: #0984e3;
  --timeline-radius: 0px;
  --large-padding: 140px;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  background: #FFF;
  color: var(--main-color)
}

.App h1, .App h2, .App h3, .App h4, .App h5, .App h6 {
  font-family: Inter,sans-serif;
  margin: 0;
}

.App h1 {
  text-transform: uppercase;
  font-size: 3rem;
  position: relative;
}

.borderLogo {
  width: 110%;
  height: 24px;
  position: absolute;
  background: #ffff157a;
  top: 24px;
}

.App h2 {
  color: var(--main-color);
}

.App .subtitle {
  font-size: 2rem;
  padding: 20px var(--large-padding) 0px var(--large-padding);
  background: var(--main-bg-color);
}

.App .summary {
  background-color: rgba(211, 224, 238, 0.5);
  margin: 0px 0 20px 0;
  border-radius: 4px;
  padding: 20px;
}

header {
  width: calc(100% - 2 * var(--large-padding));
  padding: 20px var(--large-padding);
  border-bottom: 1px solid var(--main-color);
  display: flex;
  justify-content: space-between;
}

.content {
  width: calc(100% - 2 * var(--large-padding));
  margin: 0px auto;
  padding: 20px var(--large-padding);
  flex: 1 1 auto;
  min-height: 0px;
  height: 100%;
  display: flex;
  background: #edf2f5;
  gap: 40px;
}

.content .left {
  width: 600px;
  display: flex;
  flex-direction: column;
}

.search {
  display: flex;
}

.content .search input[type=text] {
  flex: 1;
  height: 30px;
  background: transparent;
  border: 1px solid var(--main-color);
  border-radius: 4px;
  padding: 6px 12px;
  color: var(--main-color);
  margin-right: 10px;
  font-size: 1rem;
}

input[type=button], button {
  font-size: 1rem;
  height: 44px;
  background: var(--main-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  margin-right: 10px;
}

button a {
  color: white;
  text-decoration: none;
}

input[type=button]:hover, button:hover {
  cursor: pointer;
  background: #116eb9 !important;
}

input[type=button].noMargin, button.noMargin {
  margin: 0;
}

.content a, .link {
  color: var(--main-color);
  text-decoration: none;
  border-bottom: 1px solid var(--main-color);
  transition: border-width 0.1s ease-in-out;
  cursor: pointer;
}

.content a:hover, .link:hover {
  color: #116eb9;
  border-bottom: 4px solid #116eb9;
}

.right {
  height: 100%;
  display: flex;
  flex: 1;
  min-height: 0;
  flex-direction: column;
}

.results {
  height: 100%;
  overflow-y: auto;
}

.results .summary {
  margin-top: 20px;
}

.results .hint {
  padding: 6px 0 0 12px;
  font-size: .9rem;
  font-style: italic;
}

.summary p {
  margin-bottom: 0px;
}

.video {
  border-radius: 4px;
  display: flex;
  padding: 10px;
  margin-top: 10px;
  transition: background-color 0.2s ease-in-out;
}

.video:hover {
  background: rgba(211, 224, 238, 0.5);
  cursor: pointer;
}

.video.selected {
  background: rgba(211, 224, 238, 1);
}

.video .title {
  font-weight: 500;
}

.video img {
  position: relative;
  overflow: hidden;
  aspect-ratio: 16/9;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  border-radius: 6px;
  flex: 0 0 130px;
}

.video .details {
  flex: 4;
  padding: 10px;
}

.player {
  border-radius: 4px;
  flex: 1;
  color: #2d3436;
  height: fit-content;
}

.controls {
  margin-top: 14px;
}

.timeline {
  position: relative;
  flex: 1;
  height: 14px;
}

.timeline .firstground {
  border-radius: var(--timeline-radius);
  height: 8px;
  background: #2d3436;
  position: absolute;
  top: -4px;
  pointer-events: none;
}

.timeline .background {
  height: 8px;
  border-radius: var(--timeline-radius);
  width: 100%;
  background: #b2bec3;
  position: absolute;
  top: -4px;
  cursor: pointer;
}

.segments {
  position: absolute;
  height: 8px;
  border-radius: var(--timeline-radius);
  top: -4px;
  cursor: pointer;
  background: rgba(255, 118, 117, 0.7);
  min-width: 6px;
  border-left: var(--main-bg-color) 2px solid;
  border-right: var(--main-bg-color) 2px solid;
}

.segments.highlight {
  background: rgba(253, 203, 110, .7);
}

.segments.highlight:hover {
  background: rgba(253, 203, 110, 1);
}

.segments:hover {
  height: 12px;
  top: -6px;
  background: rgba(255, 118, 117, 1);
}

.buttons {
  display: flex;
  align-items: start;
}

.video-control {
  cursor: pointer;
}

.duration, .dynamicTitle, .playbackRate {
  margin-left: 10px;
  font-size: .8rem;
}

.playbackRate {
  margin-left: auto;
  cursor: pointer;
}

.admin {
  margin-top: 20px;
}

.transcript.active {
  font-weight: bold;
}

.player-wrapper {
  width: auto;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
  top: 0;
}

input[type='range'] {
  width: 60px;
  -webkit-appearance: none !important;
  background: var(--main-bg-color);
}

.volume {
  margin: -2px 0 0 10px;
}

input[type="range"]::-moz-range-thumb {
  background-color: #2d3436;
  border: 0px;
  cursor: pointer;
  padding:0;
}
input[type="range"]::-moz-range-progress {
  height: 6px;
  background-color: #2d3436;
}
input[type="range"]::-moz-range-track {
  height: 6px;
  background-color: #b2bec3;
}

.timelineHover {
  display: none;
  position: absolute;
  top: -7px;
  z-index: 1024;
  pointer-events: none;
}

.timelineHover .node {
  background: #2d3436;
  border-radius: 100%;
  height: 14px;
  width: 14px;
}

.timelineHover .text {
  top: -24px;
  position: absolute;
  background: #2d3436;
  color: white;
  padding: 2px;
  font-size: .7rem;
  text-align: center;
  left: -10px;
  border-radius: 2px;
}

.timelineHover.visible {
  display: block;
}